import React, { useState } from "react";
import {
  ChevronDown,
  MessageSquare,
  BarChart2,
  Mail,
  FileText,
  Users,
  Database,
} from "lucide-react";
import bgimg from "./edwin-andrade-4V1dC_eoCwg-unsplash.jpg";
import Alert from "./alert";

import ReactDOM from "react-dom";

const Feature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow-md">
    <Icon className="w-12 h-12 text-orange-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
  });

  const [loading, setLoading] = useState(false);
  const [successfullySubmit, setSuccessfullySubmit] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    // Handle form submission here
    console.log("Form submitted with data:", formData);
    // Reset form
    setFormData({ name: "", email: "", organization: "" });

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      name: formData.name,
      email: formData.email,
      org: formData.organization,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-edusight.cloudfunctions.net/edusight-landing-dev",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setLoading(false);
        setSuccessfullySubmit(true);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onClose = () => {
    setSuccessfullySubmit(false);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="mt-8 relative space-y-4 max-w-md mx-auto"
      >
        {successfullySubmit &&
          ReactDOM.createPortal(
            <div className="modal-overlay w-full h-fit fixed">
              <Alert onClose={onClose} />
            </div>,
            document.getElementById("rootalert")
          )}

        {loading && (
          <>
            <div className="absolute z-[1001] bg-[#00000055] w-full h-full flex items-center justify-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  class="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </>
        )}
        <div>
          <label htmlFor="name" className="sr-only">
            Full Name
          </label>
          <input
            id="name"
            name="name"
            type="text"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            Email address
          </label>
          <input
            id="email"
            name="email"
            type="email"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Email address"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="organization" className="sr-only">
            Organization
          </label>
          <input
            id="organization"
            name="organization"
            type="text"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Organization"
            value={formData.organization}
            onChange={handleChange}
          />
        </div>
        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Register Interest
          </button>
        </div>
      </form>
    </>
  );
};

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-[#ffe5a8] to-[#ff919e33]">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <span className="text-2xl font-bold text-orange-400">
                  EduSight
                </span>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {/* Hero section */}
          <div className="px-4 py-8 sm:px-0 text-center">
            <h1 className="text-4xl font-extrabold text-orange-500 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Revolutionize Your Admissions Process
            </h1>
            <p className="mt-5 max-w-xl mx-auto text-xl text-gray-500">
              EduSight: The AI-powered platform that streamlines admissions for
              universities and educational institutions.
            </p>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow">
                <a
                  href="#features"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-400 hover:bg-orange-700"
                >
                  Learn More
                  <ChevronDown
                    className="ml-2 -mr-1 h-5 w-5"
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
          </div>

          {/* University Image section */}
          <div className="mt-10 relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-3 bg-gray-100 text-lg font-medium text-gray-900">
                Empowering Education
              </span>
            </div>
          </div>
          <div className="mt-8 rounded-lg overflow-hidden shadow-xl">
            <img
              className="w-full h-fit object-cover block mx-auto"
              src="https://gsehd.gwu.edu/sites/g/files/zaxdzs4166/files/2023-09/school-counseling-illustration.jpg"
              alt="University campus"
            />
          </div>

          {/* Features section */}
          <div id="features" className="mt-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
              Our Features
            </h2>
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <Feature
                icon={MessageSquare}
                title="Intelligent Chatbot Interface"
                description="AI-powered chatbot for instant answers to admissions queries."
              />
              <Feature
                icon={Database}
                title="Knowledge Base Integration"
                description="Comprehensive database access for accurate information delivery."
              />
              <Feature
                icon={Users}
                title="Query Escalation"
                description="Seamless transition to human support when needed."
              />
              <Feature
                icon={BarChart2}
                title="Real-Time Analytics"
                description="Insights into conversations for continuous improvement."
              />
              <Feature
                icon={Mail}
                title="Personalized Outreach"
                description="AI-crafted emails to enhance student engagement."
              />
              <Feature
                icon={FileText}
                title="Content Generation"
                description="AI-suggested blog posts and marketing content ideas."
              />
            </div>
          </div>

          {/* CTA section */}
          <div className="mt-16 bg-orange-400 rounded-lg shadow-xl overflow-hidden">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:px-16 xl:py-20 xl:px-20">
              <div className="text-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  <span className="block">Ready to transform</span>
                  <span className="block">your admissions process?</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-blue-200 max-w-2xl mx-auto">
                  Register your interest today and take the first step towards a
                  smoother, more efficient admissions experience.
                </p>
                <RegisterForm />
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">
            {/* Add your social media links here */}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base text-gray-400">
              &copy; 2024 EduSight. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
