import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./home";

function App() {
  return (
    <>
      <div id="rootalert"></div>
      {/* <div className="w-[400px] flex items-center justify-center h-[725px] bg-orange-700 fixed bottom-20 right-24 cursor-pointer rounded-sm">
      </div> */}
      <LandingPage />
    </>
  );
}

export default App;
